@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400italic,400,700");
@import url("https://fonts.googleapis.com/css?family=Volkhov:400italic");
@import url("https://fonts.googleapis.com/css2?family=Archivo+Black&family=Roboto:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat:700");
@import url("https://fonts.googleapis.com/css?family=Bitter:700");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lilita+One&display=swap");

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 40px;
}

.logo img{
  width: 180px;
}

.header ul {
  list-style: none;
  display: flex;
  align-items: baseline;
}

.header li {
  margin-left: 45px;
}

.header a {
  text-decoration: none;
  font-size: 1rem;
  color: black;
}

.header a:hover,
.header a:active,
.header a.active {
  color: black;
}

/*.badge {
  background-color: white;
  color: black;
  border-radius: 12px;
  border-style: dotted;
  border-color: black;
  padding: 0 1rem;
  margin-left: 0.5rem;
}*/



/* navBar */

.topnav {
  overflow: hidden;
  background-color: #261B3F;
  display: flex;
  justify-content: space-between;
  padding: 0 19% 0 19%;
  height: 90px; /* if changing height also adjust myNavOverlay top for mobile menu */
  display: flex;
  /*justify-content: center;*/
  align-items: center;
  font-family: Freight_Text_Book, Helvetica, Arial, sans-serif;
}

.topnav a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  text-decoration: none;
  font-size: 18px; /* if changing fontsize also adjust myNavOverlay top for mobile menu */
  border-bottom: 6px solid rgba(255, 255, 255, 0);
  transition: 0.3s;
}

.topnav a:hover {
  color: #ff5238;
  /*border-bottom: 6px solid #ff5238;*/
}

.topnav a.active {
  color: #ff5238;
  /*border-bottom: 6px solid #ff5238;*/
}

a.activeMob{
  color: #ff5238 !important;
}

/*#headingLink animation*/
a#headingLink{
  font-size: 25px;
  font-family: Favorit, Helvetica, Arial, sans-serif;
  color: #291351;
  text-decoration: none;
  cursor: pointer;
}
a#headingLink:link {
  color: #291351;
}
a#headingLink:visited {
  color: #291351;
}
a#headingLink:hover {
  color: #cc4884;
}

a#headingLink
{
  font-weight: bold;
  position: relative;
  transition: 0.3s;
}

a#headingLink:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #ff5135;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s;
}

a#headingLink:hover:before {
  visibility: visible;
  transform: scaleX(1);
}

.topnav .icon {
  display: block;
}

.navLinks a{
  margin-left: 30px;
}

/* if chnaging 750 screen size for navbar also chnage screen size in topnav.js */
/*@media screen and (max-width: 750px) {
  .topnav .navLinks{display: none;}
  .topnav .icon {
    float: right;
    display: block;
  }
}*/

/* hamburger css starts */
/* css to chnage hamburger into close */
.container {
  display: inline-block;
  cursor: pointer;
}

.bar1, .bar2, .bar3 {
  width: 20px;
  height: 2px;
  background-color: #f2f2f2;
  margin: 4px 0;
  transition: 0.4s;
}

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-2px, 6px);
  transform: rotate(-45deg) translate(-2px, 6px);
}

.change .bar2 {opacity: 0;}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-2px, -6px);
  transform: rotate(45deg) translate(-2px, -6px);
}
/* hamburger css ends */

.myNavOverlay{
  background-color: #f2f2f2;
  height: 0;
  width: 100%;
  position: absolute;
  top: 90px;
  left: 0;
  overflow-y: hidden;
  transition: 0.5s;
  font-family: Favorit, Helvetica, Arial, sans-serif;
  z-index: 2;
  /*border-bottom: 2px solid #d3d3d3;*/
}

.myNavOverlayHeight{
  background-color: #f2f2f2;
  height: 380px;
  width: 100%;
  position: absolute;
  top: 90px;
  left: 0;
  overflow-y: hidden;
  transition: 0.5s;
  font-family: Favorit, Helvetica, Arial, sans-serif;
  z-index: 2;
  border-bottom: 2px solid #d3d3d3;
}

.myNavOverlay ul, .myNavOverlayHeight ul {
  padding: 20px 19%;
  margin: 0;
  list-style-type: none;
}

.myNavOverlay li, .myNavOverlayHeight li {
  padding: 18px 0;
}

.myNavOverlay a, .myNavOverlayHeight a{
  font-size: 32px;
  color: #291351;
  text-decoration: none;
  transition: 0.3s;
}

.myNavOverlay a:hover, .myNavOverlayHeight a:hover{
  color: #cc4884;
  text-decoration: none;
}

.hamColor{
  color: white;
}

.whiteLogo{
  height: 42px;
  width: 172px;
}

.blueLogo{
  display: none;
  height: 42px;
  width: 172px;
}

.blueHam{
    display: none;
  }

.vp650{
  display: none;
}

/* ----- cluster builder nav----- */



@media only screen and (max-width: 1200px) {
  .topnav {
    padding: 0 11% 0 11%;
  }

  .myNavOverlay ul, .myNavOverlayHeight ul {
    padding: 20px 11%;
    margin: 0;
    list-style-type: none;
  }

}


@media only screen and (max-width: 959px) {
  .topnav {
    background-color: #f5f5f5;
    padding: 0 11% 0 11%;
  }

  .whiteLogo{
    display: none;
  }

  .blueLogo{
    display: block;
  }

  .blueHam{
    display: block;
  }


  .myNavOverlay ul, .myNavOverlayHeight ul {
    padding: 20px 8%;
  }

}

@media only screen and (max-width: 650px) {
  .topnav {
    padding: 0 3% 0 3%;
  }

  .myNavOverlay ul, .myNavOverlayHeight ul {
    padding: 20px 3%;
  }

}


@media only screen and (max-width: 650px) {
  .blueLogo{
    height: 26px;
    width: 110px;
  }

  .blueHam{
    display: none;
  }

  .vp650{
    display: inline;
  }
}