@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Pacifico&display=swap");

@font-face {
  font-family: Favorit;
  src: url(fonts/favorit_regular.otf);
}

@font-face {
  font-family: Freight_Text_Bold;
  src: url(fonts/freight_text_bold.otf);
}

@font-face {
  font-family: Freight_Text_Book;
  src: url(fonts/freight_text_book.otf);
}

@font-face {
  font-family: FavoritStdBoldExpanded;
  src: url(fonts/FavoritStd_BoldExpanded1.otf);
}

@font-face {
  font-family: FavoritMonoStd_Light;
  src: url(fonts/FavoritMonoStd-Light.otf);
}

@font-face {
  font-family: FavoritStd_Regular;
  src: url(fonts/FavoritStd-Regular.otf);
}

* {
  box-sizing: border-box;
  /*font-family: Freight_Text_Book, Favorit, Helvetica, Arial, sans-serif;*/
  font-family: Favorit, Helvetica, Arial, sans-serif;
}

h1 {
  font-family: Favorit, Helvetica, Arial, sans-serif;
}

h2 {
  font-family: Freight_Text_Bold, Favorit, Helvetica, Arial, sans-serif;
}

h3 {
  font-family: Freight_Text_Bold, Favorit, Helvetica, Arial, sans-serif;
}



.bold {
  font-weight: bold;
}
