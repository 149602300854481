@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Pacifico&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400italic,400,700);
@import url(https://fonts.googleapis.com/css?family=Volkhov:400italic);
@import url(https://fonts.googleapis.com/css2?family=Archivo+Black&family=Roboto:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:700);
@import url(https://fonts.googleapis.com/css?family=Bitter:700);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lilita+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Pacifico&display=swap);
@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/normalize/5.0.0/normalize.min.css);
@font-face {
  font-family: Favorit;
  src: url(/static/media/favorit_regular.e63f53bd.otf);
}

@font-face {
  font-family: Freight_Text_Bold;
  src: url(/static/media/freight_text_bold.2c7210c8.otf);
}

@font-face {
  font-family: Freight_Text_Book;
  src: url(/static/media/freight_text_book.e22c1098.otf);
}

@font-face {
  font-family: FavoritStdBoldExpanded;
  src: url(/static/media/FavoritStd_BoldExpanded1.a4794805.otf);
}

@font-face {
  font-family: FavoritMonoStd_Light;
  src: url(/static/media/FavoritMonoStd-Light.fd63518b.otf);
}

@font-face {
  font-family: FavoritStd_Regular;
  src: url(/static/media/FavoritStd-Regular.09d4c6b4.otf);
}

* {
  box-sizing: border-box;
  /*font-family: Freight_Text_Book, Favorit, Helvetica, Arial, sans-serif;*/
  font-family: Favorit, Helvetica, Arial, sans-serif;
}

h1 {
  font-family: Favorit, Helvetica, Arial, sans-serif;
}

h2 {
  font-family: Freight_Text_Bold, Favorit, Helvetica, Arial, sans-serif;
}

h3 {
  font-family: Freight_Text_Bold, Favorit, Helvetica, Arial, sans-serif;
}



.bold {
  font-weight: bold;
}

.MainNavigation_header__pcWBd {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 40px;
}

.MainNavigation_logo__3mTft img{
  width: 180px;
}

.MainNavigation_header__pcWBd ul {
  list-style: none;
  display: flex;
  align-items: baseline;
}

.MainNavigation_header__pcWBd li {
  margin-left: 45px;
}

.MainNavigation_header__pcWBd a {
  text-decoration: none;
  font-size: 1rem;
  color: black;
}

.MainNavigation_header__pcWBd a:hover,
.MainNavigation_header__pcWBd a:active,
.MainNavigation_header__pcWBd a.MainNavigation_active__oN3Ka {
  color: black;
}

/*.badge {
  background-color: white;
  color: black;
  border-radius: 12px;
  border-style: dotted;
  border-color: black;
  padding: 0 1rem;
  margin-left: 0.5rem;
}*/



/* navBar */

.MainNavigation_topnav__3S6r0 {
  overflow: hidden;
  background-color: #261B3F;
  display: flex;
  justify-content: space-between;
  padding: 0 19% 0 19%;
  height: 90px; /* if changing height also adjust myNavOverlay top for mobile menu */
  display: flex;
  /*justify-content: center;*/
  align-items: center;
  font-family: Freight_Text_Book, Helvetica, Arial, sans-serif;
}

.MainNavigation_topnav__3S6r0 a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  text-decoration: none;
  font-size: 18px; /* if changing fontsize also adjust myNavOverlay top for mobile menu */
  border-bottom: 6px solid rgba(255, 255, 255, 0);
  transition: 0.3s;
}

.MainNavigation_topnav__3S6r0 a:hover {
  color: #ff5238;
  /*border-bottom: 6px solid #ff5238;*/
}

.MainNavigation_topnav__3S6r0 a.MainNavigation_active__oN3Ka {
  color: #ff5238;
  /*border-bottom: 6px solid #ff5238;*/
}

a.MainNavigation_activeMob__sZy7-{
  color: #ff5238 !important;
}

/*#headingLink animation*/
a#MainNavigation_headingLink__1HCwh{
  font-size: 25px;
  font-family: Favorit, Helvetica, Arial, sans-serif;
  color: #291351;
  text-decoration: none;
  cursor: pointer;
}
a#MainNavigation_headingLink__1HCwh:link {
  color: #291351;
}
a#MainNavigation_headingLink__1HCwh:visited {
  color: #291351;
}
a#MainNavigation_headingLink__1HCwh:hover {
  color: #cc4884;
}

a#MainNavigation_headingLink__1HCwh
{
  font-weight: bold;
  position: relative;
  transition: 0.3s;
}

a#MainNavigation_headingLink__1HCwh:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #ff5135;
  visibility: hidden;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  transition: all 0.3s;
}

a#MainNavigation_headingLink__1HCwh:hover:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}

.MainNavigation_topnav__3S6r0 .MainNavigation_icon__2ltVa {
  display: block;
}

.MainNavigation_navLinks__Raje- a{
  margin-left: 30px;
}

/* if chnaging 750 screen size for navbar also chnage screen size in topnav.js */
/*@media screen and (max-width: 750px) {
  .topnav .navLinks{display: none;}
  .topnav .icon {
    float: right;
    display: block;
  }
}*/

/* hamburger css starts */
/* css to chnage hamburger into close */
.MainNavigation_container__5WBHJ {
  display: inline-block;
  cursor: pointer;
}

.MainNavigation_bar1__1sqiu, .MainNavigation_bar2__3jFy_, .MainNavigation_bar3__3BdQo {
  width: 20px;
  height: 2px;
  background-color: #f2f2f2;
  margin: 4px 0;
  transition: 0.4s;
}

.MainNavigation_change__tT0cH .MainNavigation_bar1__1sqiu {
  -webkit-transform: rotate(-45deg) translate(-2px, 6px);
  transform: rotate(-45deg) translate(-2px, 6px);
}

.MainNavigation_change__tT0cH .MainNavigation_bar2__3jFy_ {opacity: 0;}

.MainNavigation_change__tT0cH .MainNavigation_bar3__3BdQo {
  -webkit-transform: rotate(45deg) translate(-2px, -6px);
  transform: rotate(45deg) translate(-2px, -6px);
}
/* hamburger css ends */

.MainNavigation_myNavOverlay__3qFRn{
  background-color: #f2f2f2;
  height: 0;
  width: 100%;
  position: absolute;
  top: 90px;
  left: 0;
  overflow-y: hidden;
  transition: 0.5s;
  font-family: Favorit, Helvetica, Arial, sans-serif;
  z-index: 2;
  /*border-bottom: 2px solid #d3d3d3;*/
}

.MainNavigation_myNavOverlayHeight__2x5nd{
  background-color: #f2f2f2;
  height: 380px;
  width: 100%;
  position: absolute;
  top: 90px;
  left: 0;
  overflow-y: hidden;
  transition: 0.5s;
  font-family: Favorit, Helvetica, Arial, sans-serif;
  z-index: 2;
  border-bottom: 2px solid #d3d3d3;
}

.MainNavigation_myNavOverlay__3qFRn ul, .MainNavigation_myNavOverlayHeight__2x5nd ul {
  padding: 20px 19%;
  margin: 0;
  list-style-type: none;
}

.MainNavigation_myNavOverlay__3qFRn li, .MainNavigation_myNavOverlayHeight__2x5nd li {
  padding: 18px 0;
}

.MainNavigation_myNavOverlay__3qFRn a, .MainNavigation_myNavOverlayHeight__2x5nd a{
  font-size: 32px;
  color: #291351;
  text-decoration: none;
  transition: 0.3s;
}

.MainNavigation_myNavOverlay__3qFRn a:hover, .MainNavigation_myNavOverlayHeight__2x5nd a:hover{
  color: #cc4884;
  text-decoration: none;
}

.MainNavigation_hamColor__1HZ1P{
  color: white;
}

.MainNavigation_whiteLogo__1MrCI{
  height: 42px;
  width: 172px;
}

.MainNavigation_blueLogo__2My8M{
  display: none;
  height: 42px;
  width: 172px;
}

.MainNavigation_blueHam__2kBNH{
    display: none;
  }

.MainNavigation_vp650__2gnH8{
  display: none;
}

/* ----- cluster builder nav----- */



@media only screen and (max-width: 1200px) {
  .MainNavigation_topnav__3S6r0 {
    padding: 0 11% 0 11%;
  }

  .MainNavigation_myNavOverlay__3qFRn ul, .MainNavigation_myNavOverlayHeight__2x5nd ul {
    padding: 20px 11%;
    margin: 0;
    list-style-type: none;
  }

}


@media only screen and (max-width: 959px) {
  .MainNavigation_topnav__3S6r0 {
    background-color: #f5f5f5;
    padding: 0 11% 0 11%;
  }

  .MainNavigation_whiteLogo__1MrCI{
    display: none;
  }

  .MainNavigation_blueLogo__2My8M{
    display: block;
  }

  .MainNavigation_blueHam__2kBNH{
    display: block;
  }


  .MainNavigation_myNavOverlay__3qFRn ul, .MainNavigation_myNavOverlayHeight__2x5nd ul {
    padding: 20px 8%;
  }

}

@media only screen and (max-width: 650px) {
  .MainNavigation_topnav__3S6r0 {
    padding: 0 3% 0 3%;
  }

  .MainNavigation_myNavOverlay__3qFRn ul, .MainNavigation_myNavOverlayHeight__2x5nd ul {
    padding: 20px 3%;
  }

}


@media only screen and (max-width: 650px) {
  .MainNavigation_blueLogo__2My8M{
    height: 26px;
    width: 110px;
  }

  .MainNavigation_blueHam__2kBNH{
    display: none;
  }

  .MainNavigation_vp650__2gnH8{
    display: inline;
  }
}


.main {
  position: absolute;
  padding: 0px 19%;
  width: 100%;
  background-color: white !important;
}

.elementBelowLoading {
  position: relative;
}


.mainPage {
  text-align: left;
  align-items: left;
}

.main-title {
  font-family: Favorit,Helvetica, Arial, sans-serif;
  font-size: 2.8rem;
  font-weight:normal;
  color: #291351;
  text-align: left;
  padding: 0px;
  margin: 40px 0;
}

.sub-title {
  color: #291351;
  font-family: Favorit, Helvetica, Arial, sans-serif;
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 20px;
}

.sub-sub-title {
  font-size: 24px !important;
  font-weight: 400;
}

.tableHeadings{
  font-size: 16px;
  color: #b2b2b2;
  margin-top: 5px !important;
  margin-bottom: 0px !important;
}

.card-title {
  font-family: "Open Sans", sans-serif;
  font-size: 1.8rem;
  font-weight: 800;
  color: #2c292b;
  text-align: left;
  margin-left: 0px;
  margin-top: 1em;
  color: rgb(58, 58, 58);
}
.card {
  background-color: rgb(246, 246, 252);
  height: 30vh;
  padding: 0.5em;
  margin: 0.5em;
}
.container-row {
  display: flex;
  height: 100%;
}
.row-one {
  width: 55%;
  height: 100%;
}
.row-two {
  width: 45%;
  height: 100%;
}
.image {
  height: 100%;
  width: 100%;
}
.wrapper {
  display: flex;
}
.description {
  text-align: left;
  margin-left: 2.6em;
  font-weight: 400;
}
.learnmore {
  text-decoration: none;
  text-align: left;
  margin-left: 3em;
  color: blue;
}
.no-underline {
  text-decoration: none;
}
.list {
  text-align: left;
  margin-left: 2em;
  font-size: 1.3em;
}
.card-half {
  width: 50%;
  height: 100%;
}
.container-half {
  display: flex;
  height: 100%;
}
.card-half-left {
  flex: 50% 1;
  background-color: rgb(246, 246, 252);
  padding: 1em;
}

.card-half-right {
  flex: 50% 1;
  background-color: rgb(246, 246, 252);
  padding: 1em;
  margin-left: 1em;
}

.underlined {
  text-decoration: underline;
  color: rgb(58, 58, 58);
}
.card-two {
  height: 30vh;
  padding: 0.5em;
  margin: 0.5em;
}

.details {
  text-align: left;
}
.left {
  text-align: left;
}
h2.left {
  font-size: 32px;
}
.half {
  width: 50%;
}

.no-bullet {
  list-style-type: none;
}

.row {
  width: 49%;
}

.secondrow {
  width: 50%;
}

.legend {
  padding: 6px 8px;
  font: 14px Arial, Helvetica, sans-serif;
  background: white;
  background: rgba(255, 255, 255, 0.8);
  /*box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);*/
  /*border-radius: 5px;*/
  /* line-height: 24px; */
  color: #555;
}
.legend h4 {
  text-align: center;
  font-size: 16px;
  margin: 2px 12px 8px;
  color: #777;
}


.legend span {
  position: relative;
  bottom: -1px;
}

.legend i {
  width: 18px;
  height: 18px;
  float: left;
  margin: 0 8px 0 0;
  opacity: 0.7;
}

.legend i.icon {
  background-size: 18px;
  background-color: rgba(255, 255, 255, 1);
}

.legend2 {
  padding: 6px 8px;
  font-size: 14px;
  background: white;
  background: rgba(255, 255, 255, 0.8);
  /*box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);*/
  /*border-radius: 5px;*/
  /* line-height: 24px; */
  color: #291351;
  padding: 10px;
  border: 2px solid #c3c3c3;
  border-radius: 4px;
  opacity: 1;
  transition: opacity 1s;
}

.legend2 h4 {
  text-align: center;
  font-size: 16px;
  margin: 2px 12px 8px;
  color: #777;
}


.legend2 span {
  position: relative;
  bottom: 3px;
}

.legend2 i {
  width: 18px;
  height: 18px;
  float: left;
  margin: 0 8px 0 0;
  opacity: 0.7;
}

.legend2 i.icon {
  background-size: 18px;
  background-color: rgba(255, 255, 255, 1);
}

.box {
  background-color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  height: auto;
  box-shadow: 0px 12px 18px -6px rgba(0, 0, 0, 0.3);
  margin: 20px;
}

.grid, .grid1, .grid2, .grid3, .grid4{
  min-height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  align-items: stretch;
}



.grid > div {
  flex-basis: calc(20% - 40px);
}

.grid1 > div {
  flex-basis: calc(100% );
}

.grid2 > div {
  flex-basis: calc(50%);
}

.grid3 > div {
  flex-basis: calc(33% );
}

.grid4 > div {
  flex-basis: calc(25% );
}
/*.Button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 44px;
  font-size: 24px;
  font-family: 'Roboto Condensed', sans-serif;
  border-radius: 25px;
  border-width: 0;
  background-color: #291351;
  outline: 0;
  color:white;
  padding: 20px;


  &--big {
    width: 300px;
    height: 75px;
  }
  
  &--hovered {
      background-color: #00A7CE;
  }
  
  &--disabled {
      background-color: #CECECE;
      color: #E5E5E5;
      user-select: none;
      pointer-events: none;
      
      & .Button__value {
          color: #FFFFFF;
      }
  }
  
  &__icon {
      font-family: 'Material Icons';
      width: 32px;
      font-size: 32px;
      color: #FFFFFF;
      margin-right: 16px;
  }
  
  &__value {
      color: #000000;
  }
}*/

.searchInputContainer{
  width: 100%;
}

.searchInputHalf{
  width: 100%;
  
}

.LaInputContainer{
  width: 100%;
  display: inline-block;
  /* height: 51px;
  border: 1px solid black;
  line-height: 3; */
}

.LaInputContainer2{
  width: 100%;
  display: inline-block;
  /* height: 51px;
  border: 1px solid black;
  line-height: 3; */
}

.searchInputHalfNewLine{
  width: 50%;
}
.textBoxAfterLock{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 44px;
  font-size: 24px;
  font-family: 'Roboto Condensed', sans-serif;
  border-radius: 25px;
  border-width: 0;
  background-color: #5c5c5c;
  outline: 0;
  color:white;
  padding: 20px;


  &--big {
    width: 300px;
    height: 75px;
  }
  
  &--hovered {
    background-color: #00A7CE;
  }
  
  &--disabled {
    background-color: #CECECE;
    color: #E5E5E5;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    pointer-events: none;

    & .Button__value {
      color: #FFFFFF;
    }
  }
  
  &__icon {
    font-family: 'Material Icons';
    width: 32px;
    font-size: 32px;
    color: #FFFFFF;
    margin-right: 16px;
  }
  
  &__value {
    color: #000000;
  }
}

/*.linkButton{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 44px;
  font-size: 24px;
  font-family: 'Roboto Condensed', sans-serif;
  border-radius: 25px;
  border-width: 0;
  background-color: #237bec;
  outline: 0;
  color:white;
  padding: 20px;


  &--big {
      width: 300px;
      height: 75px;
  }
  
  &--hovered {
      background-color: #00A7CE;
  }
  
  &--disabled {
      background-color: #CECECE;
      color: #E5E5E5;
      user-select: none;
      pointer-events: none;
      
      & .Button__value {
          color: #FFFFFF;
      }
  }
  
  &__icon {
      font-family: 'Material Icons';
      width: 32px;
      font-size: 32px;
      color: #FFFFFF;
      margin-right: 16px;
  }
  
  &__value {
      color: #000000;
  }
}*/

.projectNum{
  font-size: 24px;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -1px;
}

.selectorButton{
  min-width: calc(100% - 5px);
  color: #000000 !important;
}



.sBInactive{

  background-color: white !important;
  color: #291351 !important;
}
.sBActive{
  background-color: #db0025 !important;
  color: white !important;
  border-radius: 100px;
  border: 10px;
}

.lamcPadding{
 /* margin-left: 5px;
  padding-top: 1em;
  padding-bottom: 5px;*/
  
}

/*cards css*/
.pinsContainer{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}

.sumRepPins{
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}

.pinDiv{
  margin-bottom: 20px;
  position: relative;
  width: 100%;
}

.SRPinDiv{
  margin-bottom: 20px;
  position: relative;
  width: 48%;
}

.hiddenScrollBar::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-width: 950px) {
  .pinsContainer{
    justify-content: center;

  }
  .pinDiv{
    width: 75%;
  }
}

@media only screen and (max-width: 750px) {
  .pinDiv{
    width: 100%;
  }
}

.accordion {
  background-color: #fff;
  color: #291351;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: 0px solid #291351;
  text-align: left;
  outline: none;
  font-size: 16px;
  transition: 0.3s;
  /*border-top-right-radius: 6px; 
  border-top-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;*/
}

.accordion:hover {
  /*background-color: #efefef;*/
}

.active{
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

/* .accordion:after {
  content: '\002B';
  font-weight: bold;
  float: right;

} */

/*.panel {
  background-color: white;
  padding: 0 18px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}*/

.panel{
  /*background-color: #eee;*/
  background-color: white;
  padding: 0 18px;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  /*border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;*/
  border: 0px solid #291351;
  color: #291351;
}

.panelActive {
  /*background-color: #eee;*/
  background-color: white;
  padding: 0 18px;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  /*border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;*/
  border: 0px solid #291351;
  color: #291351;
}

/*button css areas pg*/
.box2{
  width: 486px;
  height: 50px;
}

.container-2{
  white-space: nowrap;
  float: left;
}

.container-2 button#newLa{
  width: 63px;
  height: 50px;
  overflow: hidden;
  text-align: left;

  font-family: "Favorit", Sans-serif;
  font-size: 18px;
  line-height: 1.4em;
  fill: #291351;
  color: #291351;
  background-color: rgba(255,255,255,0);
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #291351;
  border-radius: 0px 0px 0px 0px;
  padding: 12px 24px;
  transition: all .3s ease;

}

.container-2:hover button#newLa{
  width: 405px;
  background-color:#ff5238;
  color: white;
  border-color: #ff5238;
}

.nxtBtn{
  width: 405px;
  height: 50px;
  overflow: hidden;
  text-align: right;
  margin-left: 5px;
  white-space: nowrap;

  font-family: "Favorit", Sans-serif;
  font-size: 18px;
  line-height: 1.4em;
  fill: #291351;
  color: #291351;
  background-color: rgba(255,255,255,0);
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #291351;
  border-radius: 0px 0px 0px 0px;
  padding: 12px 24px;
  transition: all .3s ease;
}



.inputFieldPriorities{
  width: 100%;
  height: 50px;
  overflow: hidden;
  text-align: left;
  white-space: nowrap;

  font-family: "Favorit", Sans-serif;
  font-size: 18px;
  line-height: 1.4em;
  color: #291351;
  background-color: rgba(255,255,255,1);
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #291351;
  border-radius: 0px 0px 0px 0px;
  padding: 12px 24px;
  transition: all 0.3s ease;
}

.inputFieldPriorities:focus {
  outline: none;
}

.nextButton{
  width: 100%;
  height: "62px";
  overflow: hidden;
  text-align: center;
  white-space: nowrap;
  border-radius: 300px;
  border: 3px solid #CC4884;
  color: #cc4884;

  font-family: "Favorit", Sans-serif;
  font-size: 18px;
  line-height: 1.4em;
  background-color: rgba(255,255,255,1);
  padding: 12px 24px;
  transition: all 0.3s ease;
}

.nextButton:hover{
  background-color:#db0052;
  border-color: #db0052;
  color: white !important;
}

.smallButton{
    width: 100%;
    height: "62px";
    overflow: hidden;
    text-align: center;
    white-space: nowrap;
    border-radius: 300px;
    border: 3px solid #CC4884;
    color: #cc4884;
  
    font-family: "Favorit", Sans-serif;
    font-size: 18px;
    line-height: 1em;
    background-color: rgba(255,255,255,1);
    padding: 8px 24px;
    transition: all 0.3s ease;
}
.smallButton:hover{
background-color:#db0052;
border-color: #db0052;
color: white !important;
}

.generalButton{
  width: 100%;
  height: 50px;
  overflow: hidden;
  text-align: center;
  white-space: nowrap;

  font-family: "Favorit", Sans-serif;
  font-size: 18px;
  line-height: 1.4em;
  color: #291351;
  background-color: rgba(255,255,255,1);
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #291351;
  border-radius: 0px 0px 0px 0px;
  padding: 12px 24px;
  transition: all 0.3s ease;
}

.generalButton:hover{
  background-color:#db0052;
  border-color: #db0052;
  color: white;
}

.generalButton3{
  width: auto;
  height: 50px;
  border: none;
  overflow: hidden;
  text-align: right;
  white-space: nowrap;

  font-family: "Favorit", Sans-serif;
  /* font-size: 18px;
  line-height: 1.4em; */
  color: #291351;
  /*background-color: rgba(255,255,255,0);*/
  
  padding: 0px 10px 0px 10px; 
  transition: all 0.3s ease;
}

/*.container-2:hover + button.nxtBtn{
width: 76px;
}*/


/* scroll effect on location pg*/
html{
  scroll-behavior: smooth;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  color: #bbb;
  font-size: 20px;
  border-radius: 50%;
  display: block;
  transition: background-color 0.3s ease;
  margin: 20px 5px;
  z-index: 5;
}

.dot:hover {
  color: #717171;
}

.dotContainer{
  position: fixed;
  top: 40%;
}

.leaflet-control-layers{ 
  text-align: left;
}


/* tooltip Link*/
a#tooltip{
  font-family: Favorit, Helvetica, Arial, sans-serif;
  color: #db0052;
  text-decoration: none;
  cursor: pointer;
}
a#tooltip:link {
  color: #db0052;
}
a#tooltip:visited {
  color: #db0052;
}
a#tooltip:hover {
  color: #db0052;
}

a#tooltip
{
  position: relative;
}

a#tooltip:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #db0052;
  visibility: hidden;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}

a#tooltip:hover:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}

.fontStyle{
  font-family: "Favorit", Sans-serif;
}



.centeredd{
  text-align: center;
  justify-content: center;
}

.freightText{
  font-family: "Freight Text Book" !important;
}

.favorit{
  font-family: "Favorit" !important;
}

.favoritMono{
  font-family: FavoritMonoStd_Light !important;
}

/* sidenav css start */
.sidenav {
  height: 100vh;
  width: 160px;
  z-index: 1;
  background-color: #e9e9ef;
  overflow-x: hidden;
  border: 2px solid #4d4d4d;
  text-align: left;
}

.sidenav a {
  background-color: #e9e9ef;
  text-decoration: none;
  font-size: 18px;
  display: block;
  color: #291351;
}

.sidenav a:hover {
  font-weight: bold;
  color: #291351;
}


/* summary list css */
details > summary {
  padding: 6px 2px 6px 6px;
  background-color: #e9e9ef;
  border: none;
  font-size: 18px;
  /*box-shadow: 1px 1px 2px #bbbbbb;*/
  cursor: pointer;
  /*margin: 3px 0;*/
  color: #291351;
}

details > summary {
  list-style-type: none;
}

details > summary::-webkit-details-marker {
  display: none;
}

/*details > summary::after {
    content: '▶️';
}

details[open] > summary::after {
    content: '🔽';
}*/

details > summary:hover {
  font-weight: bold;
  background-color: lightgrey;
}

details > p {
  background-color: #f1f3ef;
  color: black;
  padding: 6px 2px 6px 10px;
  /*box-shadow: 1px 1px 2px #bbbbbb;*/
  margin: 0;
}

.tabContainer{
  position: absolute;
  translate: -85px;
  width: 70px;
}

/* Style the tab */
.tab {

  /* background-color: #ffffff00; */
  font-size: 18px;
  width: 86px;
  display: flex;
  flex-direction: column;
  
}

/* Style the buttons inside the tab */
.tab button {
  display: inline;
  background-color: #ffffff00;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  font-size: 17px;
  font-size: 18px;
  color: #b2b2b2;
  height: 70px;
  width: 86px;

  margin-bottom: 16px;
  transition: all 0.3s !important;
}

/* Change background color of buttons on hover */


.tab button:hover .inActivesidebarIcons{
  /* filter: invert(19%) sepia(96%) saturate(3972%) hue-rotate(327deg) brightness(81%) contrast(117%); */
  /* color: #CC4884; */
}
.tab button:hover .inActivesidebarIcons{
    -webkit-filter: invert(47%) sepia(31%) saturate(2760%) hue-rotate(303deg) brightness(83%) contrast(92%);
            filter: invert(47%) sepia(31%) saturate(2760%) hue-rotate(303deg) brightness(83%) contrast(92%);
    transition: all 0.3s !important;

}

/* Create an active/current tablink class
.tab button.active {
  background-color: rgba(219, 0, 82, 1);
  transition: all 0.3s !important;
} */

.tabSelected{
  border-left: 5px solid #db0052 !important;
  box-shadow: 3px -3px 20px -4px #00000029,3px 3px 20px -4px #00000029;
  
  transition: all 0.3s !important;
}

.tabSelected2{
    border-left: 5px solid #db0052 !important;
   
    
    transition: all 0.3s !important;
}

.tablinks{
  border-left: 5px solid #fff !important;

  transition: all 0.3s !important;
}

/* .activesidebarIconsIcon{
  font-size: 22px;
  color: #db0052;
  transition: all 0.3s !important;
}

.inActivesidebarIconsIcon{
  font-size: 22px;
  color: #291351;
  transition: all 0.3s !important;
} */

.activesidebarIcons{
  
  /* filter: invert(19%) sepia(96%) saturate(3972%) hue-rotate(327deg) brightness(81%) contrast(117%); */
  -webkit-filter: invert(47%) sepia(31%) saturate(2760%) hue-rotate(303deg) brightness(83%) contrast(92%);
          filter: invert(47%) sepia(31%) saturate(2760%) hue-rotate(303deg) brightness(83%) contrast(92%);
  /* color: #cc4884; */
  transition: all 0.3s !important;
}

.inActivesidebarIcons{
  -webkit-filter:  none;
          filter:  none;
  /* color: #b2b2b2; */
  transition: all 0.3s !important;
}



/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #291351;
  border-top: none;
  height: 300px;
  overflow-y: scroll;
}

.classOn {
  display: block;
}

.classOff {
  display: none;
  visibility: hidden;
}


/* radio/checkbox button css start */
/* The container */
.radioBContainer {
  display: block;
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radioBContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 5px;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: white;
  border: 2px solid #291351;
  border-radius: 50%;
}

/* Create a custom checkbox */
.checkmarkCB {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: white;
  border: 2px solid #291351;
}

/* On mouse-over, add a grey background color */
.radioBContainer:hover input ~ .checkmark {
  background-color: #db0052;
  border: 2px solid #db0052;
}

/* When the radio button is checked, add a blue background */
.radioBContainer input:checked ~ .checkmark, .radioBContainer input:checked ~ .checkmarkCB {
  background-color: #db0052;
  border: 2px solid #db0052;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmarkCB:after, .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radioBContainer input:checked ~ .checkmark:after, .radioBContainer input:checked ~ .checkmarkCB:after {
  /*display: block;*/
}

/* Style the indicator (dot/circle) */
.radioBContainer .checkmark:after {
  top: 11px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

/* Style the checkmark/indicator */
.radioBContainer .checkmarkCB:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.mapContainer{
  padding-bottom: 1000px;
}

.disabledTab{
  background: rgb(90, 90, 90) !important;
  visibility: hidden;
}

/* .highlightTab{
  background: yellow !important; 
} */

.MapClearbutton {
  background: rgba(255,255,255,0);
  /*box-shadow: 0 0 15px rgba(0,0,0,0.2);*/
  text-decoration: none;
  /*height: 38px;*/
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  width: auto;
  max-width: 38px; /** I'm animating max-width because width needs to be auto, and auto can't be animated **/
  transition: max-width 0.3s;
  transition: opacity 1s;
}

/*.MapClearbutton:hover {
  max-width: 185px;
  padding: 6px 8px;
}*/

.MapClearicon {
  font-size: 16px;
  margin-right: 15px;
  padding: 0px 8px;
  display: flex;
  align-items: center;
  color: #c3c3c3;
}

.MapCleartext {
  white-space: nowrap;
  padding-right: 15px;
}

.locationSideBarLayersActive{
  background-color: rgba(219, 0, 82, 0.2);
}

.inactiveClearDataButton{
  cursor: context-menu;
  opacity: 0 !important;
/*  max-width: 38px;*/
pointer-events: none;
transition: opacity 1s;
}

.messageBoxArea{
  background-color: rgba(41, 19, 81, 0.2); 
  padding: 8px 16px; 
  color: rgb(41, 19, 81);
  transition: 0.3s;
}

.leaflet-container path{ 
 transition: fill-opacity 0.7s;
}




.x0{
  opacity: 0 !important;
  transition: opacity 1s;
}

.x1{
  opacity: 1 !important;
  transition: opacity 1s !important;
  
}

.x2{
  opacity: 0 !important;
}

.x4{
  opacity: 0 !important;
  transition: opacity 0s;
}

.mapInfo{
  padding: 6px 8px;
  width: 268px;
  height: 74px;
  font: 14px/16px Arial, Helvetica, sans-serif;
  background: rgba(255,255,255,1);
  /*box-shadow: 0 0 15px rgba(0,0,0,0.2);*/
  opacity: 1;
  color: #291351;
  font-size: 16px;
  border: 2px solid #c3c3c3;
  border-radius: 4px;
  margin: 10px 10px 0 0;
  transition: opacity 1s;
}

.floatR {
  float: right;
}

.pops{
  min-width: 400px;
}


.leaflet-popup-content{
  min-width: 400px;
}




@-webkit-keyframes prioritiesFieldHighlight {
  0% {
    background: #EFEFEF; 
  }
  100% {
    background: none;
  }
}




@keyframes prioritiesFieldHighlight {
  0% {
    background: #EFEFEF; 
  }
  100% {
    background: none;
  }
}

.prioritiesFieldHighlight:hover {
  -webkit-animation: prioritiesFieldHighlight 2s;
          animation: prioritiesFieldHighlight 2s;
}


.slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;
}

/* Hide the images by default */
/*.mySlides {
  display: none;
}*/

/* Next & previous buttons */
.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover, .next:hover {
  background-color: rgba(0,0,0,0.8);
}

/* Caption text */
.text {
  color: #cc4884;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #cc4884;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@-webkit-keyframes fade {
  from {opacity: .4}
  to {opacity: 1}
}

@keyframes fade {
  from {opacity: .4}
  to {opacity: 1}
}

/* toogle button css for cards  */
.knobs, .layer
{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.knobs2, .layer
{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
/* main frame for toggle  */
.button
{
  position: relative;
  width: 120px;
  height: 20px;
  overflow: hidden;
}

/* ciomplete toggle container  */
.button.b2
{
  /*border-radius: 5px;*/
  display: inline-block;
  /*border: 1px solid #291351;*/
}

/* invisible checkbox on the top  */
.checkbox
{
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.knobs
{
  z-index: 2;
}

.knobs2
{
  z-index: 2;
}

.layer
{
  width: 100%;
  background-color: #e7e9eb;
  transition: 0.3s ease all;
}

/* Default container */
#button-10 .knobs:before, #button-10 .knobs:after, #button-10 .knobs span
{
  position: absolute;
  width: 60px;
  height: 100%;
  font-size: 12px;
  /*font-weight: bold;*/
  text-align: center;
  padding: .8px 4px;
  /*border-radius: 3px;*/
  transition: 0.3s ease all;
}

#button-10 .knobs:before
{
  content: '';
  left: 0px;
  background-color: #db0052;
}

#button-10 .knobs:after
{
  content: 'Satellite';
  right: -0px;
  color: #291351;
}

#button-10 .knobs span
{
  display: inline-block;
  left: 0px;
  color: white;
}

/* default container  */
#button-10 .checkbox:checked + .knobs span
{
  color: #291351;
}

/* satellite highlight */
#button-10 .checkbox:checked + .knobs:before
{
  left: 60px;
  background-color: #db0052;
  /*border-radius: 3px;*/
}

/* satellite hightlighted text  */
#button-10 .checkbox:checked + .knobs:after
{
  color: white;
}

/* non highlighted part color change  */
/*#button-10 .checkbox:checked ~ .layer
{
    background-color: #fcebeb;
}*/

#button-10 .knobs2:before, #button-10 .knobs2:after, #button-10 .knobs2 span
{
  position: absolute;
  width: 60px;
  height: 100%;
  font-size: 12px;
  /*font-weight: bold;*/
  text-align: center;
  padding: .8px 4px;
  /*border-radius: 3px;*/
  transition: 0.3s ease color;
}

#button-10 .knobs2:before
{
  content: '';
  left: 0px;
  background-color: #ffffff;
}

#button-10 .knobs2:after
{
  content: 'Satellite';
  right: -0px;
  color: #b2b2b2;
  background-color: white;
}

#button-10 .knobs2 span
{
  display: inline-block;
  left: 0px;
  color: #cc4884;
  background-color: white;
}

/* default container  */
#button-10 .checkbox:checked + .knobs2 span
{
  color: #b2b2b2;
}

/* satellite highlight */
#button-10 .checkbox:checked + .knobs2:before
{
  left: 60px;
  background-color: #ffffff;
  /*border-radius: 3px;*/
}

/* satellite hightlighted text  */
#button-10 .checkbox:checked + .knobs2:after
{
  color: #cc4884;
}


/* ----report sidebar css start----  */
.sidebarContainer{
  position: fixed;
  top: 204.5px;
  left: 0px;
  width: 40px;
  height: 350px;
  opacity: 0.4;
  transition: 0.3s;
}

.sidebarContainer:hover{
  opacity: 1;
}

.sidebar{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sidebar .group{
  margin: 10px 0;
}

.sidebar .group button{
  background-color: #e7e9eb;
  border: 0px;
  opacity: 1;
}

.sidebar .group button:hover{
  /*background-color: black !important;*/
}

.sidebar .group button .inActivesidebarIconsR{
  /*filter: invert(4%) sepia(85%) saturate(6609%) hue-rotate(270deg) brightness(76%) contrast(92%);*/
  -webkit-filter: none;
          filter: none;
}

.sidebar button:hover .inActivesidebarIconsR{
  /*filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(69deg) brightness(109%) contrast(101%);*/
  -webkit-filter: invert(19%) sepia(96%) saturate(3972%) hue-rotate(327deg) brightness(81%) contrast(117%);
          filter: invert(19%) sepia(96%) saturate(3972%) hue-rotate(327deg) brightness(81%) contrast(117%);
  
}


.sidebar .group button .activesidebarIconsR{
  -webkit-filter: invert(19%) sepia(96%) saturate(3972%) hue-rotate(327deg) brightness(81%) contrast(117%);
          filter: invert(19%) sepia(96%) saturate(3972%) hue-rotate(327deg) brightness(81%) contrast(117%);
}

.sidebarButtonSelected{
  border-left: 5px solid #db0052 !important;
  /*background-color: black !important;*/
  opacity: 1 !important;
}

.rankListPinDiv{
  padding: 20px 0px;
  width: 100%;
}

/*.customer:nth-child(even){
  background-color: #e7e9eb;
}*/



/*.customer:hover {background-color: #EFEFEF !important;}*/

.closeDataBoxButton{
  font: 16px/14px Tahoma, Verdana, sans-serif;
  color: #c3c3c3;
  text-decoration: none;
  font-weight: bold;
}

.closeDataBoxButton:hover{
  color: #999;
}

.selectedHeading{
  color: #db0052 !important;
}

.inputField{
  width: 100%;
  height: 50px;
  overflow: hidden;
  text-align: center;
  white-space: nowrap;

  font-family: "Favorit", Sans-serif;
  font-size: 18px;
  line-height: 1.4em;
  color: #291351;
  background-color: rgba(255,255,255,1);
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #291351;
  border-radius: 0px 0px 0px 0px;
  padding: 12px 24px;
  transition: all 0.3s ease;
}

.noHover{
  background-color: white;
  border: 2px solid #291351;
}

/* On mouse-over, add a grey background color */
.radioBContainer:hover input ~ .checkmarknoHover {
  background-color: white;
  border: 2px solid #291351;
}

.checkmarkWidget {
  border: 2px solid #cccccc;
}


.scrollEffect{
  overflow-y:auto;
  position: absolute;
  width: 350px;
  height: 84%;
  top: 8%;
  left: 0;
  z-index: 9999;
  background-color: white;
  padding: 20px 40px;
  font-family: 'Favorit, Helvetica, Arial, sans-serif';
  /* border-right: 1px solid #291351; */
  color: '#291351';
  box-shadow: 0px 0px 12px 4px #00000029;
}

.scrollEffect::-webkit-scrollbar { width: 0 !important }
.scrollEffect { overflow: -moz-scrollbars-none; }
.scrollEffect { -ms-overflow-style: none; }

.pinContainer{
  position: absolute;
  top: 8%;
  left: 0;
  height: 84%;
  width: 100%;
  z-index: 9999;
  background-color: white;
  box-shadow: 0px 0px 12px 4px #00000029;
}

/*.modalConatiner{
  height:100vh; 
  position:fixed; 
  top:0;
  left:0;
  width: 100%; 
  backgroundColor: rgba(0, 0, 0, 0);
  zIndex: 10000; 
  display: none;
  justifyContent: center; 
  alignItems: center;
}
*/
.modalVisible{
  display: flex !important;
}

.modalVisible2{
  display: block !important;
}

.viz{
  visibility: visible !important;
}

.hidez{
    visibility: hidden !important;
}

.hidezFull{
    visibility: hidden !important;
    display: none !important;
}

.rc-slider-rail {
  width: 99.9% !important;
  border-radius: 0px !important;
}

.rc-slider-dot {
  z-index: -1 !important;
  margin-left: -1px !important;
  bottom: 0px !important;
}

.visx-axis-tick {
  overflow: visible !important;
}

.svg:not(:root) {
  overflow: visible !important;
}
.modalList{
  counter-increment: my-awesome-counter;
}

.modalList::before {
  content: counter(my-awesome-counter) ". ";
  color: #db0052;
}



/* ----- header ------ */
header{
  height: 676px;
  background-color: white;
} 

header img{
  height: 676px;
}


@media screen and (max-width: 1700px) {
 header{
  height: 600px;
} 

header img{
  height: 600px;
}

.desktopHeader{
  display: block;
  background-size: 1800px 600px !important;
  /*height: 73vh;*/
  background-repeat: no-repeat;
  background-position: 75% top;
}

}

@media screen and (max-width: 1500px) {
 header{
  height: 511px;
} 

header img{
  height: 511px;
}

.desktopHeader{
  display: block;
  background-size: 1500px 511px !important;
  /*height: 73vh;*/
  background-repeat: no-repeat;
  background-position: 75% top;
}

}


footer p{
  margin: 0;
  color: white;
}

footer a{
  text-decoration: none;
}

footer{
  font-family: Favorit !important;
}

.headerImg{
  width: 100%;
  position: absolute;
}

.headerHeadingDiv{
  position: absolute; 
  padding: 150px 19% 0 19%;
  color: #261B3F;
  width: 90%;
}

.headerHeading {
  width:100% !important;
  font-size: 5.8vw !important; 
  margin:0 !important; 
  padding:0 !important; 
  line-height: 110% !important; 
  font-family: FavoritStdBoldExpanded !important; 
}

.headerHeading2 {
    width:100% !important;
    font-size: 94px !important; 
    margin:0 !important; 
    padding:0 !important; 
    line-height: 110% !important; 
    font-family: FavoritStdBoldExpanded !important; 
}

.div2nd{
  width: 100%;
  padding: 20px 19% 64px 19%;
}

.div2nd h1{
  margin: 44px 0 32px; 
  font-size: 64px;
  font-family: FavoritStdBoldExpanded;
}

footer{
  padding: 66px 19% 58px; 
  background-color: #261B3F;
}

footer .divContainer{
  display: flex;
  justify-content: space-between;
}

.navigationFooter{
  display: flex; 
  flex-direction: column;
}

.footer2{
  background-color: #ededed;
  width: 100%; 
  display: none;
}

.footer2Logo{
  height: 42px;
  width: 172px;
}

.newButton{
  border-radius: 39px;
  border-color: #291351;
  width: 378px;
  height: 62px; 
  font-family: FavoritMonoStd_Light;
  font-size: 24px;
}

.mobHeader{
  display: none;
}

.desktopHeader{
  display: block;
  background-size: 2200px 676px;
  /*height: 73vh;*/
  background-repeat: no-repeat;
  background-position: 75% top;
}

@media only screen and (max-width: 1200px) {
  .main {
    padding: 0px 11%;
  }

  header{
    height: 400px;
  } 

  .headerImg{ 
    height: 400px;
  }

  .headerHeadingDiv{
    padding: 90px 11% 0 11%;
    width: 100%;
  }

  .headerHeading {
    width: 80% !important;
  }

  .desktopHeader{
    display: block;
    background-size: 1300px 400px !important;
    /*height: 73vh;*/
    background-repeat: no-repeat;
    background-position: 75% top;
  }

  .div2nd{
    width: 100%;
    padding: 20px 11% 64px 11%;
  }

  .div2nd h1{
    margin: 44px 0 32px; 
    font-size: 44px !important;
    font-family: FavoritStdBoldExpanded;
  }

  footer{
    padding: 66px 11% 58px;
  }


  .newButton{
    width: auto;
    padding: 0 20px;
  }

}



@media only screen and (max-width: 959px) {
  .main {
    padding: 0px 8%;
  }

  header{
    height: 300px;
    min-height: 85vh;
  } 

  .mobHeader{
    display: block;
    background-size: 1300px 400px;
    height: 73vh;
    background-repeat: no-repeat;
    background-position: 75% top;
  }

  .desktopHeader{
    display: none;
  }

  .headerImg{ 
    height: 300px;
  }

  .headerHeadingDiv{
    padding: 90px 8% 0 8%;
  }

  .headerHeading {
    width: 80% !important;
    font-size: 44px  !important; 
  }

  .button{
    width: auto;
    height: 38px;
  }

  #tools{
    display: none;
  }

  .div2nd{
    display: none;
    width: 100%;
    padding: 20px 8% 64px 8%;
  }

  .div2nd h1{
    margin: 44px 0 32px; 
    font-size: 38px !important;
    font-family: FavoritStdBoldExpanded;
  }

  .footer2{
    display: flex;
    /*padding: 66px 8% 58px;*/
    padding: 66px 8% 58px;
    justify-content: space-between;
  }

  footer{
    /*padding: 66px 8% 58px;*/
    display: none;
  }

}


@media only screen and (max-width: 650px) {
  .main {
    padding: 0px 3%;
  }

   header{
    height: 250px;
  } 

  .headerImg{ 
    height: 250px;
  }

  .headerHeadingDiv{
    padding: 90px 3% 0 3%;
  }

  .footer2{
    display: flex;
    /*padding: 66px 8% 58px;*/
    padding: 66px 3% 58px;
    justify-content: space-between;
  }

  .footer2 p{
    font-size: 10px !important;
  }

  .footer2Logo{
    height: 26px;
    width: 110px;
  }

  .newButton{
    font-size: 16px;
  }

}


@media only screen and (max-width: 450px) {
  .headerHeading{
    width: auto !important;
  }
}

.toolContainer{
  display: grid;
  grid-template-columns: 31% 31% 31%;
  grid-gap: 3.5%;
  margin-bottom: 118px;
}

.toolContainer2{
    display: grid;
    grid-template-columns: 48% 48%;
    grid-gap: 4%;
    margin-bottom: 77px;
  }

.barz{
  background: #EDEDED;
}

.barzTop{
  background: linear-gradient(90deg, rgba(226,99,93,1) 0%, rgba(204,72,132,1) 100%);
}

#cardsScroll{
  position: absolute;
  width: 350px;
  left: 0px;
  top: 0px;
  height: 80%;
}

.w0{
  width: 0% !important;
}
.w20{
  width: 20% !important;
}
.w40{
  width: 40% !important;
}
.w60{
  width: 60% !important;
}
.w80{
  width: 80% !important;
}
.w99{
  width: 99% !important;
}

.yesButton:hover {
  color: #fff !important;
  background-color: #CC4884 !important;
  transition: all 0.3s ease;
}

.noButton:hover {
  color: #cc4884  !important;
  background-color: #fff !important;
  transition: all 0.3s ease;
}
/* getStarted */
.gs:hover{
  background-color: #cc4884 !important;
  color: #fff !important;
  transition: all 0.3s ease;
  border-color: #cc4884 !important;
}

svg:hover {
  fill: red;
}
svg{
  fill: #b2b2b2;
}

.customer{
  height: 48px;
  font-size: 16px;
  line-height: 16px;
  /* padding: 16px 0px; */
  width: 100%s;
  display: flex;
}

.pinsRankDarkBackground
{
  background-color: #ededed;
}
